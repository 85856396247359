<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="getTableList">
      <el-tab-pane label="客户端房间标签" name="0">
      </el-tab-pane>
      <el-tab-pane label="后台房间标签" name="1">
      </el-tab-pane>
    </el-tabs>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入标签名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button> -->
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>
    <el-table border :data="tableData" height="68vh" style="width: 99%">
        <el-table-column prop="id" label="id" width="100"></el-table-column>
        <el-table-column prop="sort" label="排序" width="100"></el-table-column>
        <el-table-column prop="tagName" label="标签名称" width="150"></el-table-column>
        <el-table-column label="背景颜色" width="150">
          <template slot-scope="scope">
            <div class="colorBox">
              <p>{{scope.row.color}}</p>
              <div :style="{backgroundColor:formatColorSixteen(scope.row.color)}" class="colorBg"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="字体颜色" width="150">
          <template slot-scope="scope">
            <div class="colorBox">
              <p>{{scope.row.fontColor}}</p>
              <div :style="{backgroundColor:formatColorSixteen(scope.row.fontColor)}" class="colorBg"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="tagName" label="可见用户类型" v-if="activeName=='1'">
          <template slot-scope="scope">
            <p v-if="scope.row.visibleEditingEntity.whiteList || scope.row.visibleEditingEntity.noWhiteList">
              身份1：
              <span v-if="scope.row.visibleEditingEntity.whiteList">白名单用户、</span>
              <span v-if="scope.row.visibleEditingEntity.noWhiteList">非白名单用户、</span>
            </p>
            <p v-if="scope.row.visibleEditingEntity.wallet || scope.row.visibleEditingEntity.nft">
              身份2：
              <span v-if="scope.row.visibleEditingEntity.wallet">有钱包</span>
              <!-- <span v-if="scope.row.visibleEditingEntity.nft">有nft、</span> -->
            </p>
            <p v-if="scope.row.visibleEditingEntity?.countryGroupId?.length>0">
              国家组：
              <span v-for="item in scope.row.visibleEditingEntity.countryGroupId" :key="item">{{ countryGroupOptions[item].name }}、</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="tagName" label="优先用户类型">
          <template slot-scope="scope">
            <p v-if="scope.row.priorityEditingEntity.whiteList || scope.row.priorityEditingEntity.noWhiteList">
              身份1：
              <span v-if="scope.row.priorityEditingEntity.whiteList">白名单用户、</span>
              <span v-if="scope.row.priorityEditingEntity.noWhiteList">非白名单用户、</span>
            </p>
            <p v-if="scope.row.priorityEditingEntity.wallet || scope.row.priorityEditingEntity.nft">
              身份2：
              <span v-if="scope.row.priorityEditingEntity.wallet">有钱包</span>
              <!-- <span v-if="scope.row.priorityEditingEntity.nft">有nft、</span> -->
            </p>
            <p v-if="scope.row.priorityEditingEntity?.countryGroupId?.length>0">
              国家组：
              <span v-for="item in scope.row.priorityEditingEntity.countryGroupId" :key="item">{{ countryGroupOptions[item].name }}、</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="白名单" v-if="activeName=='1'">
          <template slot-scope="scope">
            <p>{{scope.row.whiteListEntity.join(',')}}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button size="small" type="primary" @click="toEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="danger" 
              @click="delFunc(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
    </el-table>
    
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}${activeName=='0'?'客户端':'后台'}房间标签`" :visible.sync="addVisible" width="650px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="ruleFormBox"
      >
        <el-form-item label="标签名称" prop="tagName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.tagName"
          ></el-input>
        </el-form-item>
        <el-form-item label="标签背景颜色" prop="color" :rules="rules.changeRule">
          <el-color-picker v-model="ruleForm.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="标签字体颜色" prop="fontColor" :rules="rules.changeRule">
          <el-color-picker v-model="ruleForm.fontColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="排序" prop="sort" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.sort"
          ></el-input>
        </el-form-item>
        <el-form-item label="白名单" v-if="activeName=='1'">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="英文逗号分割"
            v-model="ruleForm.whiteListEntity">
          </el-input>
        </el-form-item>
        <div class="subItem" v-if="activeName=='1'">
          <div class="subTitle">可见编辑</div>
          <el-form-item label="身份1">
            <el-checkbox v-model="ruleForm.visibleEditingEntity.whiteList">白名单用户</el-checkbox>
            <el-checkbox v-model="ruleForm.visibleEditingEntity.noWhiteList">非白名单用户</el-checkbox>
          </el-form-item>
          <el-form-item label="身份2">
            <el-checkbox v-model="ruleForm.visibleEditingEntity.wallet">有钱包</el-checkbox>
            <!-- <el-checkbox v-model="ruleForm.visibleEditingEntity.nft">有nft</el-checkbox> -->
          </el-form-item>
          <el-form-item label="国家组">
            <el-checkbox-group v-model="ruleForm.visibleEditingEntity.countryGroupId">
              <el-checkbox :label="Number(key)" v-for="(item,key) in countryGroupOptions" :key="key">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div class="subItem">
          <div class="subTitle">优先编辑</div>
          <el-form-item label="身份1">
            <el-checkbox v-model="ruleForm.priorityEditingEntity.whiteList">白名单用户</el-checkbox>
            <el-checkbox v-model="ruleForm.priorityEditingEntity.noWhiteList">非白名单用户</el-checkbox>
          </el-form-item>
          <el-form-item label="身份2">
            <el-checkbox v-model="ruleForm.priorityEditingEntity.wallet">有钱包</el-checkbox>
            <!-- <el-checkbox v-model="ruleForm.priorityEditingEntity.nft">有nft</el-checkbox> -->
          </el-form-item>
          <el-form-item label="国家组">
            <el-checkbox-group v-model="ruleForm.priorityEditingEntity.countryGroupId">
              <el-checkbox :label="Number(key)" v-for="(item,key) in countryGroupOptions" :key="key">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {visibleEditingEntity: {},priorityEditingEntity: {}},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      dialogVisible: false,
      activeName:'0',
      countryGroupOptions: {},
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{pagination},
  created() {
    this.getTableList();
    this.getCountryGroupList();
  },
  methods: {
    async getCountryGroupList() {
      let res = await this.$http.countryGroupConfigList();
      if (res) {
        let countryGroup = res.data.filter(x=>x.id==400001)[0].configList
        this.countryGroupOptions = countryGroup.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
      }
    },
    addFunc(){
      this.ruleForm = {visibleEditingEntity: {whiteList:false,noWhiteList:false,wallet:false,nft:false,countryGroupId:[]},priorityEditingEntity: {whiteList:false,noWhiteList:false,wallet:false,nft:false,countryGroupId:[]}};
      this.addVisible = true;
    },
    toEdit(data) {
      //编辑
      this.ruleForm = JSON.parse(JSON.stringify(data))
      this.ruleForm.color = this.formatColorSixteen(this.ruleForm.color)
      this.ruleForm.fontColor = this.formatColorSixteen(this.ruleForm.fontColor)
      if(this.ruleForm.whiteListEntity) {
        this.ruleForm.whiteListEntity = this.ruleForm.whiteListEntity.join(',')
        
      }
      if(!this.ruleForm.visibleEditingEntity?.countryGroupId) {
        this.ruleForm.visibleEditingEntity.countryGroupId = []
      }
      if(!this.ruleForm.priorityEditingEntity?.countryGroupId) {
        this.ruleForm.priorityEditingEntity.countryGroupId = []
      }
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
          ruleForm.color = this.formatColorTen(ruleForm.color);
          ruleForm.fontColor = this.formatColorTen(ruleForm.fontColor);
          ruleForm.type = Number(this.activeName);
          if(ruleForm.whiteListEntity) {
            ruleForm.whiteListEntity = ruleForm.whiteListEntity.split(',').map(x=>Number(x))
          } else {
            ruleForm.whiteListEntity = []
          }
          let res = await this.$http.roomTagConfigSave({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    formatColorTen(color){
      const hexColorWithHash = color
      const hexColor = hexColorWithHash.slice(1); 
      const decimalColor = parseInt('FF'+hexColor, 16);
      return decimalColor
    },
    formatColorSixteen(color){
      if(!color) return;
      const hex = '#' + color.toString(16).slice(2);
      return hex
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.roomTagConfigList({...this.searchForm,type:this.activeName});
      if (res) {
        this.tableData = res.result.records;
        this.totalNum = res.result.total;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.roomTagConfigDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.icon{
  width: 60px;
}
.colorBox{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.colorBg{
  width: 50px;
  height: 30px;
  border: 1px solid #ccc;
}
.subItem{
  background: #f0f2f5;
  padding: 10px 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.subTitle{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 10px;
}
</style>
